<template>
  <v-alert
    :type="item.color"
    border="left"
    prominent
    transition="fade-transition"
    dismissible
    class="mb-1"
    @input="$store.commit('removeSuccess', index)"
  >
    <template #prepend>
      <v-icon large> mdi-check-circle </v-icon>
    </template>
    <v-row style="max-width: 250px" class="mx-4 text-wrap">
      <span v-html="item.message" />
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'SuccessItem',
  props: {
    item: {
      type: Object,
      default: () => ({
        message: 'Успешно сохранено!',
        color: 'success',
      }),
    },
    index: {
      type: Number,
    },
  },
};
</script>
<style scoped>
.v-alert__content {
  z-index: 9999 !important;
}
/*
    .v-alert--text:before {
        background-color: #000!important;
        opacity: 0.85!important;
    }
*/
</style>
