interface ICheckParams {
  objectId: string;
  executetime: string;
  checktype: string;
}

export default class Uri {
  baseURL: string = (process.env.VUE_APP_HOST ? process.env.VUE_APP_HOST : '') + process.env.VUE_APP_API;
  /*Common*/
  designConfig: string = 'system/color';
  userInfo: string = 'current-user/detail-info';
  changePassword: string = 'current-user/update-password';
  changeRole: string = 'users/update-active-role';
  infoSystemInfo: string = 'info/systeminfo/';
  infoSystemInfoLogin: string = 'info/systeminfologin';
  i18nLkk: string = 'i18n';
  about: string = 'info/version/all';
  modulesAbout: string = 'info/version/all-modules';
  logout: string = 'logout';
  env: string = 'env';

  /*Login*/
  login: string = 'login';

  /*LKB*/
  usertakers: string = 'user/takers';
  applicationStatuscount: string = 'application/status-count-all';
  statuses: string = 'status';
  userSigner: string = 'user/signer';
  application: string = 'application/';
  appIdNew(id: string): string {
    return `${this.application}find/${id}`;
  }
  appId(id: string): string {
    return `${this.application}${id}/`;
  }
  clientDocs(id: string): string {
    return `${this.appId(id)}all-document`;
  }
  clientDocsDelete: string = `${this.application}delete-document`;
  downloadClientDocsExel(id: string): string {
    return `${this.appId(id)}docs-excel`;
  }
  fieldDocs(id: string): string {
    return `${this.appId(id)}employee-document`;
  }
  fieldDocumentTypes(id: string): string {
    return `info/fielddocumenttypes/${id}`;
  }
  actualAddress(appId: string): string {
    return `${this.appId(appId)}actualaddress`;
  }
  regAddress(appId: string): string {
    return `${this.appId(appId)}regaddress`;
  }
  okpo(appId: string): string {
    return `${this.appId(appId)}okpo`;
  }
  ogrn(appId: string): string {
    return `${this.appId(appId)}ogrn`;
  }
  kpp(appId: string): string {
    return `${this.appId(appId)}kpp`;
  }
  okato(appId: string): string {
    return `${this.appId(appId)}okato`;
  }
  okved(appId: string): string {
    return `${this.appId(appId)}okved`;
  }
  commentApp(appId: string): string {
    return `${this.appId(appId)}comment`;
  }
  // listOkato:string = 'application/okato';
  // listOkved:string = 'application/okved';
  //todo переделать запрос. date сдеать параметром в теле запроса
  lifeDate(appId: string, date: string): string {
    return `${this.application}${appId}/lifedate/${date}`;
  }
  updateCheckResult({ objectId, executetime, checktype }: ICheckParams): string {
    return `check/${objectId}/${executetime}/${checktype}/result`;
  }
  updateCheckComment({ objectId, executetime, checktype }: ICheckParams): string {
    return `check/${objectId}/${executetime}/${checktype}/comment`;
  }
  recheck(id: string): string {
    return this.appId(id) + 'recheck/';
  }
  downloadReport(id: string, date: string, type: string): string {
    return `check/${id}/${date}/${type}/report`;
  }
  downloadZipped(uuids: string, appId: string): string {
    return this.binary + '/' + uuids + '/' + appId + '/zipped/';
  }
  downloadBankForm(appId: string): string {
    return this.binary + '/' + appId + '/generatedbankdoc/';
  }
  downloadSheetApproval(appId: string): string {
    return this.binary + '/' + appId + '/generated-approval-sheet';
  }
  confirm(docId: string): string {
    return `${this.application}document-requirement/${docId}/verify`;
  }
  downloadSignature(uuids: string, appId: string): string {
    return this.application + '/' + uuids + '/download-signature';
  }
  deleteDoc(fileId: string): string {
    return `${this.application}document-file/${fileId}/delete`;
  }
  // deleteDoc(appId, docId):string{return `${this.appId(appId)}doc/${docId}`};
  docAddRequirement(appId: string): string {
    return `${this.appId(appId)}document-requirement/create`;
  }
  docAddRequirementV2(appId: string): string {
    return `${this.appId(appId)}document-requirement/create-by-subtype`;
  }
  docRequirement(fileId: string): string {
    return `${this.application}document-requirement/${fileId}/create`;
  }
  docRecalled(fileId: string): string {
    return `${this.application}document-requirement/${fileId}/recalled`;
  }
  docAddDell(fileId: string): string {
    return `${this.application}document-requirement/${fileId}/delete`;
  }
  checkOriginal(fileId: string): string {
    return `${this.application}document-file/${fileId}/check-original`;
  }
  downloadDocument(fileId: string): string {
    return `file-download/${fileId}`;
  }
  downloadDigitalDocument(fileId: string): string {
    return `digital-document-download/${fileId}`;
  }
  downloadFile(docId: string): string {
    return this.binary + '/' + docId;
  }
  uploadDocument(appId: string): string {
    return `${this.appId(appId)}add-document`;
  }
  questionnaire(id: string): string {
    return `${this.appId(id)}questionnaire-new/`;
  }
  questionnaireStepOne(id: string): string {
    return `${this.appId(id)}questionnaire-first-step`;
  }
  searchAddr: string = 'suggest/address';
  searchOrg: string = 'suggest/org';
  searchCountry: string = 'suggest/country';

  updateCheckOldResultExecutetime(objectId: string): string {
    return `check/smaw-old/${objectId}`;
  }
  updateSmawCurrentCheckResultExecutetime(objectId: string): string {
    return `check/smaw/${objectId}`;
  }

  pendingList: string = 'pending-list';
  updatePending: string = 'pending-update';
  deletePending(id: string): string {
    return `pending/${id}`;
  }


  /*Applications*/

  applications: string = 'application/all';
  applicationsTimeDownload: string = 'application/all/download';
  appSaveFilter: string = 'application/filter/'; //todo перенести в store/user
  blocks: string = 'application/all-block';
  applicationPostActInDiadoc: string = 'application/post-act-in-diadoc';
  applicationCreateActInDiadoc: string = 'application/create-act-in-diadoc';
  sendActToEdo(agentId: string) { return `sbis/send-new-documents/${agentId}`};
  sendDocumentsToEdo(appId: string){ return `sbis/send-exist-documents/${appId}`};

  actionsWork(id: string) {
    return `${this.appId(id)}work`;
  }
  appAction({ appId, actionId }: { appId: string; actionId: string }): string {
    return `${this.appId(appId)}step/${actionId}`;
  }
  generateBankForm(appId: string): string {
    return `${this.appId(appId)}generatebankform`;
  }
  generateLinkLkk(appId: string): string {
    return 'application/auth-client/' + appId;
  }
  getLinkLkkApp(appId: string): string {
    return 'link-login-to-lkk/' + appId;
  }
  updateAppForAbs(appId: string): string {
    return 'updateAppForAbs/' + appId;
  }
  updateDataApp(appId: string): string {
    return 'application/synchronization/' + appId;
  }
  filterModels: string = 'filter-models';
  filterModelsCreate: string = 'filter-models/create';
  deleteFilterModels(id: string): string {
    return `filter-models/delete/${id}`;
  }
  storageSettings: string = 'storage-settings';
  applicationSignBinary(uuids: string): string {
    return 'application/' + uuids + '/sign';
  }

  applicationDocumentsForms(uuids: string): string {
    return 'application/' + uuids + '/documents-forms';
  }
  applicationDocumentsFormSpostDocumentInDiadoc(uuids: string): string {
    return 'application/' + uuids + '/post-document-in-diadoc';
  }

  downloadFiledocument(docId: string) {
    return 'file-download/' + docId;
  }
  /*ClientList*/
  clientReport: string = 'client/report/';
  reportCount: string = 'client/report/count';
  deleteClient(id: string): string {
    return `client/${id}`;
  }
  clientApplications(id: string): string {
    return `client/application?clientId=${id}`;
  }
  deleteClientApp(id: string): string {
    return `client/application?appId=${id}`;
  }

  /*bank questionnairies*/
  bankForm: string = 'bankform';
  typeDocsLkbFormBank: string = 'documenttypes/subtype/LKB_FORM_BANK';

  valuesForm: string = 'valuesform';
  testbankform: string = 'testbankform';
  form: string = 'form';
  migration: string = 'form/migration';
  form2: string = 'bankform';
  downloadForm(id: string): string {
    return `${this.form}/${id}`;
  }
  downloadForm2(id: string): string {
    return `${this.form2}/${id}`;
  }
  formLkr: string = 'form?system=LKR';
  formLkk: string = 'form?system=LKK';

  /*StatusModel*/
  statebranch: string = 'statebranch';
  statebranchId(id: string): string {
    return `${this.statebranch}/${id}`;
  }
  branchtype: string = 'branchtype';
  lkbrole: string = 'lkbrole';
  status: string = 'status';
  transitionsConditions: string = 'status/automatic-settings/all';
  updateTransitionsConditions: string = 'status/automatic-settings/update';

  /*DocumentsTypes*/
  documenttype: string = 'documenttype';
  documenttypeId(id: string, type: string): string {
    return `${this.documenttype}/${id}/${type}`;
  }
  unBlockedDocs(id: string, type: string): string {
    return `${this.documenttype}/${id}/${type}/extract-archive`;
  }
  order: string = 'documenttype/order';
  documenttypeInfo: string = 'documenttype/info';
  blockedDocs: string = 'documenttype/all-delete';
  getDocumentTypesBySubtype: string = 'get-document-type-by-sub-type';
  addParticipant(appId: string, fileId: string) {
    return `generate-docs/${appId}/downloaded/add-participant/${fileId}`;
  }
  signedDocTypes(appId: string): string {
    return `generate-docs/${appId}/signed-doc-type`;
  }
  participantSignatories(appId: string): string {
    return `generate-docs/${appId}/signed-participant `;
  }
  dynamicDictionary(appId: string): string {
    return `generate-docs/${appId}/value-dictionary`;
  }
  generateDocs(appId: string): string {
    return `generate-docs/${appId}/form `;
  }
  bankSignatoriesByOfficeId(officeId:string): string {
    return `users/signed-bank/${officeId}`;
  }
  temporaryDocuments(appId: string): string {
    return `${this.appId(appId)}temporary-document`;
  }

  deleteTemporaryDocument(appId: string, fileId: string): string {
    return `${this.appId(appId)}temporary-document/${fileId}`;
  }

  downloadTemporaryDocument(appId: string, fileId: string): string {
    return `${this.appId(appId)}temporary-document/${fileId}/upload`;
  }

  /*Internationalization*/
  binary: string = 'binary';
  binaryLegEntity: string = this.binary + '/legal-entity';
  binaryIndividual: string = this.binary + '/individual';
  binaryManager: string = this.binary + '/employee';
  localeList: string = 'i18n/language';
  /*get - получить список, post - изменить*/
  localeSelect(id: string): string {
    return `i18n/${id}`;
  }

  /*Users/users*/
  users: string = 'users/all';
  roles: string = 'role/all';
  client: string = 'client';
  rolesSigners: string = 'role/signers'; // Роли которые Подписанты
  addUser: string = 'users/add';
  validUserDomain(domainName: string): string {
    return `users/validate/${domainName}`;
  }
  updateUser: string = 'users/update';
  blockedUsers: string = 'users/all/archive';
  blockedUser(id: string): string {
    return `users/to-archive/${id}`;
  }
  unBlockedUser(id: string): string {
    return `users/extract-archive/${id}`;
  }

  /*Users/roles*/
  menu: string = 'client-menu/all';
  menuTree: string = 'client-menu/all-tree';
  privileges: string = 'privilege/all';
  addRole: string = 'role/add';
  updateRole: string = 'role/update';
  sortRole: string = 'role/update/order';
  blockedRoles: string = 'role/all/archive';
  blockedRole(id: string): string {
    return `role/to-archive/${id}`;
  }
  unBlockedRole(id: string): string {
    return `role/extract-archive/${id}`;
  }

  /*Administration*/
  mainsysset: string = 'admin/sysflag';
  sysParameters: string = 'admin/sysparameter';
  dropBD: string = 'admin/truncatedb';
  exportInfo: string = 'database/info';
  downloadFileExportInfo: string = 'database/export';
  uploadSetting: string = 'database/import';
  desingEnvLkb: string = 'design/env/LKB';
  desingEnvLkk: string = 'design/env/LKK';
  desingEnv: string = 'design/env/';
  uploadEnvImage: string = 'design/env/image';
  updateSystem: string = 'questionnaire/update-all-counterparties';
  isUpdateSystem: string = 'env/updated-form ';

  referralLinksAll: string = 'referral-links/all';
  referralLinksSave: string = 'referral-links/save';
  deleteReferralLinks(schemaId: string): string {
    return `referral-links/${schemaId}`;
  }

  fileDownload(srcImg: string): string {
    return `file-download/${srcImg}`;
  }
  binaryLoadImg(srcImg: string): string {
    return `${this.binary}/${srcImg}`;
  }
  clientFile(srcImg: string): string {
    return `${this.binary}/client/${srcImg}`;
  }
  binaryLoadImgDesing(srcImg: string): string {
    return `${this.baseURL}${this.binary}/${srcImg}`;
  }
  marketing = 'admin/administration/marketing';
  marketings = this.marketing + 's';

  /*ApplicationStatuses*/
  canrequestdocs(id: string): string {
    return `${this.status}/${id}/canrequestdocs`;
  }
  showfinalresult(id: string): string {
    return `${this.status}/${id}/showfinalresult`;
  }
  statusId(id: string): string {
    return `${this.status}/${id}`;
  }

  /*Checks*/
  check: string = 'check';
  checkOrder: string = 'check/order';
  checkId(id: string): string {
    return `${this.check}/${id}`;
  }
  checkinfo: string = 'checkinfo';
  checkGroup: string = 'check/group';
  checkGroupId(id: string): string {
    return `${this.checkGroup}/${id}`;
  }
  checkGroupOrder: string = 'check/group/order';
  checkOkved: string = 'check/okved';
  checkInn: string = 'check/inn';
  uploadStatementDocument(appId: string): string {
    return `check/egrul-download/${appId}`;
  };
  manualCheckFile: string  =  `check/manual/file`;

  /*Setting: IntegrationAbs*/
  internalserviceGetparameters: string = 'internalservice/getparameters';
  statusIntegrationAbs: string = 'status';
  internalserviceSaveparameters: string = 'internalservice/saveparameters';

  egrulForm: string = 'egrul-form';
  settingsApiXml: string = 'settings-api-xml';

  /*Jobs*/
  jobs: string = 'jobs';
  job: string = 'job';
  changeJob(id: string): string {
    return this.job + '/' + id;
  }

  /*Setting: Reserve-currency-accounts */
  reserveCurrencyAccountSetting: string = 'currency/setting-accounts';
  createReserveCurrencyNumber: string = 'currency/create-account';
  editReserveCurrencyNumber: string = 'currency/edit-account';
  reserveCurrencyList(appId: string) {
    return `/currency/list-accounts/${appId}`;
  }
  deleteReserveCurrencyNumber: string = 'currency/delete-account';
  notifyCurrencyAccount: string = 'currency/notify-account';

  /*Setting: Currency-account */
  currencyAccountSetting: string = 'currency/id-currency-account';
  currencyTransitAccounts(appId: string) {
    return `/currency/list-foreign-exchange-accounts/${appId}`;
  }
  createTransitCurrencyAccountAuto(appId: string) {
    return `/currency/create-foreign-exchange-account-auto/${appId}`;
  }
  createAndUpdateTransitCurrencyAccount: string = 'currency/create-update-foreign-exchange-account';
  deleteTransitCurrencyAccount: string = 'currency/delete-foreign-exchange-account';
  // editReserveCurrencyNumber:string = 'currency/edit-account';
  // reserveCurrencyList(appId: string) { return `/currency/list-accounts/${appId}`;};
  // deleteReserveCurrencyNumber:string ='currency/delete-account';
  // notifyCurrencyAccount:string = 'currency/notify-account';

  /*Setting: ModalReserveAccount */
  reservationAccount(appId: string) {
    return `reservation/account/${appId}`;
  }

  /*Setting: BanksAndOffices*/
  office: string = 'office';
  bankOffices: string = 'bankoffices';
  bankOfficesOrder: string = 'office/update/order';
  regionOfficesOrder: string = 'region/update/order';
  region: string = 'region';
  regionId(id: string): string {
    return `${this.region}/${id}`;
  }
  showRegionId(id: string): string {
    return `${this.region}/extract-archive/${id}`;
  }
  bank: string = 'bank';
  bankId(id: string): string {
    return `${this.bank}/${id}`;
  }
  saveOffice(bankid: string, regionid: string): string {
    return `${this.office}/${bankid}/${regionid}`;
  }
  officeId(id: string): string {
    return `${this.office}/${id}`;
  }
  showOfficeId(id: string): string {
    return `${this.office}/extract-archive/${id}`;
  }

  /*Notification*/
  mailTemplate: string = 'mailtemplate';
  mailTemplateId(id: string): string {
    return `${this.mailTemplate}/${id}`;
  }
  smsTemplate: string = 'smstemplate';
  smsTemplateId(id: string): string {
    return `${this.smsTemplate}/${id}`;
  }
  event: string = 'event';
  notified: string = 'notified';
  notifiedInfo: string = 'notified/info';
  notifiedId(id: string): string {
    return `${this.notified}/${id}`;
  }

  /*report*/
  billing: string = 'report/billing';
  dadata: string = 'report/dadata';
  resourceBilling: string = '/report/billing/check';
  resourceDadata: string = '/report/dadata/check';
  downlBilling: string = 'report/billing/download';
  downlDadata: string = 'report/dadata/download';
  processingTime: string = 'report/processing-time';
  processingTimeDownload: string = 'report/processing-time/download';
  applicationTimeDownload: string = 'report/application-report/download ';

  processingTimeOfficeWorkTime: string = 'report/processing-time/filter/office-work-time';
  processingTimeHolidays: string = 'report/processing-time/filter/holidays';
  processingTimeSave: string = 'report/processing-time/filter/save';

  getProcessingTimeFilters: string = 'report/processing-time/filters';
  createProcessingTimeFilters: string = 'report/processing-time/filters/create';
  deleteProcessingTimeFilters(schemaId: string): string {
    return `report/processing-time/filters/${schemaId}`;
  }
  billingSettings: string = 'report/billing/settings';

  /* app sections */

  stateRequestDigitalProfile(snils: string) {
    return `digital-profile/fl/${snils}`;
  };
  createRequestDigitalProfile: string = 'digital-profile/fl';
  downloadDigitalProfile(snils: string) {
    return `digital-profile/fl/create-act/${snils}`;
  };
  secionList: string = 'sections/all';
  sectionSave: string = 'sections/save';
  sectionUpdateName: string = 'sections/update-name';
  sectionUpdateActive: string = 'sections/update-active';
  sectionSorted: string = 'sections/all-sorted';

  agentSave: string = 'agent';
  agentSaveUp(id: string): string {
    return `agent/${id}`;
  }
  agentAll: string = 'agent/all';
  agentToArchive(id: string): string {
    return `agent/to-archive/${id}`;
  }
  agentExtractArchive(id: string): string {
    return `agent/extract-archive/${id}`;
  }

  userAgentSave: string = 'user-agent';
  userAgentSaveUp(id: string): string {
    return `user-agent/${id}`;
  }
  userAgentAll: string = 'user-agent/all';
  userAgentToArchive(id: string): string {
    return `user-agent/to-archive/${id}`;
  }
  userAgentExtractArchive(id: string): string {
    return `user-agent/extract-archive/${id}`;
  }
  applicationAppIdReportDate(id: string): string {
    return `application/${id}/report-date`;
  }

  applicationAppIdAgent(id: string): string {
    return `application/${id}/agent`;
  }
  agentListAr: string = 'agent';
  userAgentAllAgentId(id: string): string {
    return `user-agent/all/${id}`;
  }
  bankMaterialsAll: string = 'bank-materials/all';
  postBankMaterials: string = 'bank-materials';
  bankMaterialsId(id: string): string {
    return `bank-materials/${id}`;
  }
  bankMaterialsToActive(id: string): string {
    return `bank-materials/to-active/${id}`;
  }
  bankMaterialsFromActive(id: string): string {
    return `bank-materials/from-active/${id}`;
  }

  appReport = 'report/application-report';
  getAppReportTable: string = this.appReport + '/table';
  getAppReportCount: string = this.appReport + '/count';
  getAppReportFilters: string = this.appReport + '/filters';
  createAppReportFilters: string = this.appReport + '/filters/create';
  deleteAppReportFilters(schemaId: string): string {
    return `${this.appReport}/filters/${schemaId}`;
  }

  usersPhone = 'user-phone';
  deleteUsersPhone(schemaId: string): string {
    return `user-phone/${schemaId}`;
  }

  usersGroupAll = 'users-group/all';
  usersGroup = 'users-group';
  deleteUsersGroup(schemaId: string): string {
    return `users-group/${schemaId}`;
  }
  notificationTypes(docId: string, docLe: string): string {
    return `${this.application}/sending-type/${docId}/${docLe}`;
  }
  notificationSendingDocs(appId: string, target: string): string {
    return `${this.application}/${appId}/sending-docs/${target}`;
  }

  /*analytics*/
  initTableAnalytic: string = '/analytics/report';
  getAnalyticSchema: string = '/analytics-schema';
  updateAnalyticSchema: string = '/analytics-schema-update';
  deleteAnalyticSchema(schemaId: string): string {
    return `/analytics-schema-delete/${schemaId}`;
  }

  /*updateColorSchemaDesign*/
  updateColorSchemaDesign(id: string): string {
    return `/current-user/update-color-schema-design/${id}`;
  }
  phoneContactAll: string = '/phone-contact/all';
  phoneContact: string = '/phone-contact';
  phoneContactId(id: string): string {
    return `${this.phoneContact}/${id}`;
  }
  mangoSettings: string = '/mango-settings';
  rafpSettings: string = '/rafp-settings';

  logo_img: string = this.baseURL + 'logo/login';
  logo_header: string = this.baseURL + 'logo/system';
  bg_img: string = this.baseURL + 'system/image/BG_MAIN_IMAGE';
  bg_content_img: string = this.baseURL + 'system/image/BG_CONTENT_IMAGE';

  /*LKU settings lkk-user-api*/

  lkkUserApi: string = 'lkk-user-api';
  lkkUserApiAll: string = `${this.lkkUserApi}/all`;
  lkkUserApiFileById(id: string): string {
    return `${this.lkkUserApi}/${id}/file`;
  }
  lkkUserApiBlockedById(id: string): string {
    return `${this.lkkUserApi}/${id}/blocked`;
  }
  listInn: string = `/check/inn/download`;
}

export const uri = new Uri();
