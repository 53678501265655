import { IData } from '@/types/globalTypes';
import { IBaseOffice2, IRegion } from '@/store/bank';
//https://back.test.ros.ifrbanking.ru/api/application/find/ce54544f-ba96-4922-998e-d8e2e38fd1de
export class Okved implements IOkved {
  'id': number;
  'code': string;
  'name': string;
  'integrumCode': string;
  'fullName': string;
  constructor({ id = 0, code = '', name = '', integrumCode = '', fullName = '' } = {}) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.integrumCode = integrumCode;
    this.fullName = fullName;
  }
}
export interface IOkved {
  id: number;
  code: string;
  name: string;
  integrumCode: string;
  fullName: string;
}

export class Okato implements IOkato {
  'id': number;
  'code': string;
  'name': string;
  'integrumCode': string;
  'fullName': string;
  'hibernateLazyInitializer': {};

  constructor({ id = 0, code = '', name = '', integrumCode = '', fullName = '', hibernateLazyInitializer = {} } = {}) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.integrumCode = integrumCode;
    this.fullName = fullName;
    this.hibernateLazyInitializer = hibernateLazyInitializer;
  }
}
export interface IOkato {
  id: number;
  code: string;
  name: string;
  integrumCode: string;
  fullName: string;
  hibernateLazyInitializer: {};
}
interface IOwnershipForm {
  id: number;
  name: string;
  integrmumCode: string;
}
interface IBankoffice extends IBaseOffice2 {
  region: IRegion;
  num: number;
}
interface IStakeholders {
  id: IStakeholdersId;
  objecttype: string;
  founder: boolean;
  chief: boolean;
  integrum: boolean;
  ceo: boolean;
  nonResident: boolean;
  share: number;
  amountShare: number;
  country: string;
  legalEntity: ILegalEntity;
  address: IAddress;
  individual: IIndividual;
}
interface IStakeholdersId {
  applicationId: string;
  objectId: string;
}
interface ILegalEntity {
  id: string;
  name: string;
  inn: string;
  kpp: string;
  ogrn: string;
  hibernateLazyInitializer: {};
}
interface IAddress {
  id: string;
  country: string;
  area: string;
  fias: string;
  kladr: string;
  source: IData;
}
interface IIndividual {
  id: string;
  fio: string;
  name: string;
  surname: string;
  parname: string;
  inn: string;
  dateBirth: string;
  passport: IPassport;
  position: string;
  chief: boolean;
  initials: string;
  initialsShort: string;
}
interface IPassport {
  id: string;
  issuedate: string;
  unitcode: string;
  docnumber: string;
  docserial: string;
  registeraddress: string;
  fullAddress: IData;
}
interface IType {
  id: number;
  code: string;
  name: string;
  numberPrefix: string;
}
class Type implements IType {
  'id' = 0;
  'code' = '';
  'name' = '';
  'numberPrefix' = '';
}
interface IStatusHistoryList {
  id: IStatusHistoryListId;
  initiator: string;
  role: string;
  comment: string;
  datetime: string;
  status: IStatus;
}
interface IStatusHistoryListId {
  applicationId: string;
  status: IStatus;
  datetime: string;
}
export interface IStatus {
  id: number;
  code: string;
  name: string;
  info: string;
  color: string;
  message: string;
  nameLkk: string;
  comment: string;
  showFinalResult: boolean;
  canRequestDocs: boolean;
  hibernateLazyInitializer: {};
}
class Status implements IStatus {
  id = 0;
  code = '';
  name = '';
  info = '';
  color = '';
  message = '';
  nameLkk = '';
  comment = '';
  showFinalResult = false;
  canRequestDocs = false;
  hibernateLazyInitializer = {};
}

export interface ISignatures {
  id: IsignatureId;
  signed: boolean;
  comment: string;
  role: IRole;
  lkbEmployee: ILkbEmployee;
  resoultion: string;
}
interface IsignatureId {
  applicationId: string;
  employeeId: string;
  dateTime: string;
}
interface IRole {
  id: number;
  active: boolean;
  name: string;
  orderPosition: number;
  delete: boolean;
  privileges: IPrivileges[];
  menuTree: IMenuTree[];
  applicationBlock: IApplicationBlock[];
  hibernateLazyInitializer: {};
}
interface IPrivileges {
  id: number;
  name: string;
  description: string;
  isCreated: boolean;
  isRead: boolean;
  isUpdate: boolean;
  isDelete: boolean;
  isExecute: boolean;
}
interface IMenuTree {
  id: number;
  name: string;
  url: string;
  parentUrl?: string;
}
interface IApplicationBlock {
  id: number;
  orderNum: number;
  code: string;
  name: string;
}
interface ILkbEmployee {
  id: string;
  userid: string;
  surname: string;
  name: string;
  parname: string;
  dateDocument: string;
  numberDocument: string;
  nameEmployee: string;
  email: string;
  deleted: boolean;
  revokeFromApplication: boolean;
  fio: boolean;
  hibernateLazyInitializer: {};
}

interface IMetaInfoList {
  id: IMetaInfoListId;
  value: boolean;
}
interface IMetaInfoListId {
  applicationId: string;
  key: string;
}

export interface IClient {
  id: string;
  objecttype: string;
  number: number;
  createdate: string;
  phone: string;
  email: string;
  active: boolean;
  deleted: boolean;
  objectId: string;
  individual: IIndividual2;
  fio: string;
  name: string;
}
class Client implements IClient {
  'id' = '';
  'objecttype' = '';
  'number' = 0;
  'createdate' = '';
  'phone' = '';
  'email' = '';
  'active' = false;
  'deleted' = false;
  'objectId' = '';
  'individual' = <IIndividual2>new Individual2();
  'fio' = '';
  'name' = '';
}

export interface IAgent {
  active: boolean;
  bic: string;
  checkingAccount: string;
  createDate: string;
  fioEio: string;
  fullName: string;
  id: string;
  inBank: string;
  inn: string;
  kpp: string;
  location: string;
  manager: any;
  managerEmail: string;
  managerPhone: string;
  ogrn: string;
  positionEio: string;
  shortName: string;
  tariff1: string;
  tariff2: string;
  tariff3: string;
  tariff4: string;
  tariff5: string;
  tariff6: string;
  contractDate: string;
  contractNumber: string;
}
class Agent implements IAgent {
  active = true;
  bic = '';
  checkingAccount = '';
  createDate = '';
  fioEio = '';
  fullName = '';
  id = '';
  inBank = '';
  inn = '';
  kpp = '';
  location = '';
  manager = {};
  managerEmail = '';
  managerPhone = '';
  ogrn = '';
  positionEio = '';
  shortName = '';
  tariff1 = '';
  tariff2 = '';
  tariff3 = '';
  tariff4 = '';
  tariff5 = '';
  tariff6 = '';
  contractDate = '';
  contractNumber = '';
}

interface IIndividual2 {
  id: string;
  fio: string;
  name: string;
  surname: string;
  parname: string;
  chief: boolean;
  initials: string;
  initialsShort: string;
}
class Individual2 implements IIndividual2 {
  'id' = '';
  'fio' = '';
  'name' = '';
  'surname' = '';
  'parname' = '';
  'chief' = false;
  'initials' = '';
  'initialsShort' = '';
}

interface IHead {
  present: true;
}

interface IMetainfo {
  actualAddressMatch: string;
  inviteManager: string;
  passedStage: string;
  notifyByEmail: string;
  notifyByPhone: string;
}
class Metainfo implements IMetainfo {
  'actualAddressMatch' = '';
  'inviteManager' = '';
  'passedStage' = '';
  'notifyByEmail' = '';
  'notifyByPhone' = '';
}

export interface IChecks {
  [key: string]: IChecksApp;
}
export interface IChecksApp {
  id: string;
  title: string;
  type: string;
  checks: IChecksNesting;
}
interface IChecksNesting {
  [key: string]: IChecksData[];
}
export interface IChecksData {
  group: IChecksDataGroup;
  checks: IChecksDataChecks[];
}
export interface IChecksDataGroup {
  id: number;
  name: string;
  sort: number;
  deleted: boolean;
  canDelete: boolean;
  failColorMode: string;
  mode: string;
  color: string;
}
export interface IChecksDataChecks {
  id: IChecksDataCheckId;
  modifytime: string;
  result: string;
  verifier: string;
  groupid: number;
  status: string;
  checkDict: ICheckDict;
  payload: IPayload;
  binaryInfo: IBinaryInfo;
  changeable: boolean;
  diffLast: boolean;
  valid: boolean;
  typeEnum: string;
  comment: string;
  color?: string;
  iid?: number;
}
interface IChecksDataCheckId {
  objectId: string;
  executetime: string;
  checktype: number;
}
interface ICheckDict {
  id: number;
  checkOptions: ICheckOptions[];
  checkVariables: ICheckVariables[];
  severity: string;
  type: string;
  name: string;
  individual: boolean;
  legalentity: boolean;
  disabled: boolean;
  deleted: boolean;
  counterparty: boolean;
  autoRejection: boolean;
  groupid: number;
  systemid: number;
  ordernum: number;
  group: ICheckDictGroup;
  optionMap: IoptionMap;
  variableMap: IVariableMap;
  uploadFile: boolean;
}
export interface ICheckOptions {
  id: ICheckOptionsId;
  text: string;
}
interface ICheckOptionsId {
  id: number;
  status: string;
}
interface ICheckVariables {
  id: ICheckVariablesId;
  value: string;
  type: string;
  key: string;
}
interface ICheckVariablesId {
  id: number;
  key: string;
}
interface ICheckDictGroup {
  id: number;
  name: string;
  sort: number;
  deleted: boolean;
  canDelete: boolean;
  failColorMode: string;
}
interface IoptionMap {
  STATE_FAIL: string;
  STATE_EXTERNAL_ERROR: string;
  STATE_OK: string;
  STATE_UNDEFINED: string;
  STATE_IN_PROGRESS: string;
}
interface IVariableMap {
  EXCEPTIONS_NAME_INTERNAL_API: IDataValue;
  SUCCESS_KEY_INTERNAL_API: IDataValue;
  EXCEPTIONS_FIELD_INTERNAL_API: IDataValue;
  CHECK_EXCEPTIONS_FIELD_INTERNAL_API_CHECKBOX: IDataValue;
  SUCCESS_VALUE_INTERNAL_API: IDataValue;
  ERROR_KEY_INTERNAL_API: IDataValue;
  URL_INTERNAL_API: IDataValue;
  COMMENT_KEY_INTERNAL_API: IDataValue;
  ERROR_VALUE_INTERNAL_API: IDataValue;
}
interface IDataId {
  id: number;
  key: string;
}
interface IDataValue {
  id: IDataId;
  value: string;
  type: string;
  key: string;
}
export interface IPayload {
  [key: string]: any;
  notes4staff: string;
  description: string;
  applications: string;
  status: string;
  stakeholders: string;
  accstoppedlist: string;
  ageStakeholders: string;
  companyList: string;
  founders: string;
}
export interface ICompanyList {
  inn: string;
  name: string;
  address: string;
}
export interface IApplicationsArr {
  id: number;
  inn: string;
  orgName: string;
  number: number;
  createDate: string;
  client: string;
  status: string;
}
export interface ICronosArr {
  id: string;
  fio: string;
  color?: string;
  status?: string;
  result: string;
}
export interface IAgeStakeholders {
  dateBirth: string;
  fio: string;
  position: string;
  share?: string;
  age: number | string;
  status: string;
}
export interface IFounders {
  fio: string;
  inn: string;
  name: string;
  registerDate: string;
  status: string;
  registerAddress: string;
  share?: string;
  founderName: string;
}

export interface IAccstoppedlist {
  NOMER: string;
  DATA: string;
  KODOSNOV: string;
  IFNS: string;
  BIK: string;
  DATABI: string;
}

export interface INotes4staff {
  [key: string]: string;
}
export interface INotes4staffTable {
  typeChecks: string;
  message: string;
  color: string;
}
export interface IStakeholdersPayload {
  name?: string;
  series?: string;
  number?: number;
  status?: string;
  msgInnCft?: string;
  color: string;
}
interface IBinaryInfo {
  id: string;
  name: string;
  mimetype: string;
  createDate: string;
}

export interface IAppHistories {
  id: IAppHistoriesId;
  initiator: string;
  event: string;
  role: string;
  comment: string;
  lkbUserId: string;
  datetime: string;
}
interface IAppHistoriesId {
  applicationId: string;
  dateTime: string;
}

export interface IBuildRowName {
  text: string;
  link: string;
}

interface IBinaryInfoClients {
  /*"111": [],
    "Приказ о вступлении в должность Единоличного Исполнительного Органа": [],
    "Протокол (решение) об избрании Единоличного Исполнительного Органа": [],
    "Паспорт": [],
    "test./,type": [],
    "Документы, удостоверяющие личности лиц, указанных в карточке с образцами подписей и оттиском печати, и лиц действующих по доверенности от имени юридического лица": [],
    "Документы, подтверждающие финансовое положение юридического лица": [],
    "Документы, удостоверяющие личности лиц, указанных в карточке с образцами подписей и оттиском печати, и лиц действующих по доверенности от имени юридического лица*": [],
    "Протокол (Решение) общего собрания учредителей/Акционеров о создании (об учреждении – для обществ с ограниченной ответственностью) юридического лица – Клиента*": [],
    "Учредительные документы": [],
    "Документы, подтверждающие полномочия лиц, указанных в карточке с образцами подписей и оттиском печати, на распоряжение денежными средствами, находящимися на банковском счете": [],
    "666 Документы, удостоверяющие личности лиц, указанных в карточке с образцами подписей и оттиском печати, и лиц действующих по доверенности от имени юридического лица*": [],
    "222 Учредительные документы": []*/
  [key: string]: any[];
}

export interface IAction {
  name: string;
  action: string;
  type: string;
}
export interface IApplication {
  id: string;
  number: number;
  auto: boolean;
  directLink: any;
  utmSource: any;
  utmMedium: any;
  utmCampaign: any;
  actualAddressObject: IData; //dadata
  regAddressObject: IData; //dadata
  clientinternalid: number;
  regaddress: string;
  regagency: string;
  actualaddress: string;
  regdate: string;
  createdate: string;
  modifydate: string;
  lifedate: string;
  orgnameshort: string;
  orgnamefull: string;
  orgnamealien: string;
  inn: string;
  kpp: string;
  ogrn: string;
  okpo: string;
  okfs: string;
  okogu: string;
  okopf: string;
  oktmo: string;
  comment: string;
  stage: string;
  stagedescription: string;
  authorizedcapital: number;
  finalized: boolean;
  writeBankForm: boolean;
  okopfcode: string;
  regionFromRegAddress: string;
  legalForm: IData;
  okved: IOkved;
  okato: IOkato;
  ownershipForm: IOwnershipForm;
  integrumId: number;
  counterparties: IItemCounterparties[];
  bankoffice?: IBankoffice;
  stakeholders: IStakeholders[];
  type: IType;
  statusHistoryList: IStatusHistoryList[];
  signatureList: ISignatures[];
  requirements: [];
  metaInfoList: IMetaInfoList[];
  agent: IAgent;
  client: IClient;
  licenses: [];
  actualStatus: IStatus;
  actualRealStatus: IStatus;
  numberInvoice?: string;
  cardAccount?: string;
  currencyInvoice?: [];
  remainingDays: number;
  controlEvents: [];
  objectId: string;
  head: IHead;
  currency: boolean;
  innEnum: string;
  applicationNumber: string;
  metainfo: IMetainfo;
  empty: boolean;
  rapf: boolean;
  additionalActualAddress?: string;
  clientABSNumber?: string;
  additionalNotificationInfo?: {
    id: string | null | undefined;
    fio: string | null | undefined;
    emails: string[];
  };
}

interface IItemCounterparties {
  id: string;
  inn: string;
  name: string;
  factaddress: string;
}

export class App {
  application = <IApplication>{
    id: '',
    client: <IClient>new Client(),
    actualStatus: <IStatus>new Status(),
    innEnum: '',
    regaddress: '',
    regAddressObject: {},
    type: <IType>new Type(),
    metainfo: <IMetainfo>new Metainfo(),
    orgnameshort: '',
    regdate: '',
    authorizedcapital: 0,
    actualaddress: '',
    additionalActualAddress: '',
    directLink: '',
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    inn: '',
    kpp: '',
    ogrn: '',
    okpo: '',
    okato: <IOkato>new Okato(),
    okved: <IOkved>new Okved(),
    comment: '',
  };
  signatures: ISignatures[] = [];
  callInfos = [];
  checks: IChecks = {};
  appHistories: IAppHistories[] = [];
  binaryInfoClients: IBinaryInfoClients = {};
  binaryInfoClientsRequirements = [];
  binaryInfoBankForms = {};
  actions: IAction[] = [];
  finishStatus = false;
}
