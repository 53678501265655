import { axios } from '@/plugins/http-axios';
import cons from '@/store/cons';
import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { IData } from '@/types/globalTypes';
import { IFile } from '@/types/docTypes';
interface IEnvLkk {
  [key: string]: string | boolean;
}
interface IFlag {
  code: string;
  desc: string;
  value: boolean;
  affectedSystems: string[];
}
export interface IParameter {
  code: string;
  desc: string;
  value: string;
  resulttype: string;
}
interface ISystemInfo {
  flags: IFlag[];
  parameters: [];
}

interface IEnvState {
  env: {
    flags: IFlag[];
    parameters: IParameter[];
    lkk: IEnvLkk;
  };
  cons: IData;
}
export default <Module<IEnvState, RootState>>{
  state: {
    env: {
      flags: [],
      parameters: [],
      lkk: {},
    },
    cons: cons,
  },
  mutations: {
    setEnv(state, data: ISystemInfo) {
      state.env.flags = data.flags;
      state.env.parameters = data.parameters;
    },
    setEnvLkk(state, data: IEnvLkk = {}) {
      state.env.lkk = data;
    },
  },
  actions: {
    getEnvLkk({ commit, dispatch, rootGetters }): Promise<void> {
      //todo перенести этот зарос в getEnv
      return axios
        .get<IEnvLkk>(rootGetters.uri.env)
        .then((resp) => {
          commit('setEnvLkk', resp.data);
          return Promise.resolve();
        })
        .catch((e) => {
          e.uri = rootGetters.uri.env;
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
    getEnv({ commit, dispatch, rootGetters }): Promise<void> {
      //todo выпилить i18n из ответа и перенести в запросы i18n
      return axios
        .get(rootGetters.uri.infoSystemInfo)
        .then((resp) => {
          commit('setI18nBank', resp.data.i18n, { root: true });
          commit('setEnv', resp.data);
          return Promise.resolve();
        })
        .catch((e) => {
          e.uri = rootGetters.uri.infoSystemInfo;
          dispatch('setError', e);
          return Promise.reject(e);
        });
    },
  },
  getters: {
    envLkk: (state): IEnvLkk => state.env.lkk,
    cons: (state): IData => state.cons,
    trBgColorClientDoc: (state, getters, rootState, rootGetters) => (file: IFile): string => {
      if (file.type === state.cons.APP_DOC_TYPE_REQ) return '#ddd'; //Белый

      if (file.isSignFinished) return rootGetters.theme.customerDocumentsValid;

      switch (file.status) {
        case rootGetters.cons.APP_DOC_STATUS_FOR_CHECK:
          return rootGetters.theme.customerDocumentsForCheck; //Желтый
        case rootGetters.cons.APP_DOC_STATUS_VALID:
          return rootGetters.theme.customerDocumentsValid; //Зелёный
        case rootGetters.cons.APP_DOC_STATUS_NOT_VALID:
          return rootGetters.theme.customerDocumentsNotValid; //Красный
        case rootGetters.cons.APP_DOC_STATUS_PROCESS:
          return rootGetters.theme.customerDocumentsProcess; //Голубой
        default:
          return '';
      }
    },

    //todo Нужно переделать на объект по ключам code
    flag:
      (state) =>
      (code: string): boolean => {
        const flag = state.env.flags.find((flag) => flag.code === code);
        return flag ? flag.value : false;
      },
    isRequestForAdditionalDoc: (state, getters): boolean => getters.flag('REQUEST_FOR_ADDITIONAL_DOCUMENTS'),
    isDarkTheme: (state, getters): boolean => getters.flag('DARK_THEME_ACTIVATE'),
    //todo Нужно переделать на объект по ключам code
    sysParameter:
      (state) =>
      (code: string): IParameter | undefined =>
        state.env.parameters.find((p) => p.code === code),
    finalResultContract: (state, getters): boolean | undefined =>
      getters.sysParameter('FINAL_RESULT')?.value == 'contract' || false,

    /*
        finalResultName: (state, getters):string => {

            const finalResult:IParameter|undefined = getters.finalResult;
            let finalResultName = '';

            if (finalResult)
                for (let type in finalResult.variants)
                    if (type === finalResult.value)
                        finalResultName = finalResult.variants[type].value;

            return finalResultName;
        },
*/
    isHideOffice: (state, getters): boolean => getters.flag('HIDE_OFFICE'),
    isComment: (state, getters): boolean => getters.flag('SHOW_COMMENT'),
    isDynamicSec: (state, getters): boolean => getters.flag('DYNAMIC_SECTIONS'),
    isTwoSideSigningOfDocumentsEp: (state, getters): boolean => getters.flag('BILATERAL_SIGNING_OF_DOCUMENTS_EP'),
    isSigningUnepDocument: (state, getters): boolean => getters.flag('SIGNING_UNEP_DOCUMENTS'),
    isUnepFioSn: (state, getters): boolean => getters.flag('UNEP_FIO_SN'),
    isRequestDigitalProfileFl: (state, getters): boolean => getters.flag('REQUEST_DIGITAL_PROFILE_FL'),
    isDiadoc: (state, getters): boolean => getters.flag('DIADOC_ENABLE'),
  },
};
