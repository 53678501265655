import { _genId } from '@ifr/form-questionnaire/src/plugins/formUtilites';
type TColor = 'success' | 'info' | 'warning' | 'error';
import { Module } from 'vuex';
export interface IMessage {
  message: string;
  color?: TColor;
  daley?: number;
  manual?: boolean;
}
interface IMessageSys extends IMessage {
  key: number;
}
interface IMessageStore {
  storeSuccess: IMessageSys[];
}
export default <Module<IMessageStore, any>>{
  state: {
    storeSuccess: [],
  },
  mutations: {
    removeSuccess(state, i: number = 0) {
      if (i < state.storeSuccess.length) state.storeSuccess.splice(i, 1);
    },
    addSuccess(state, item: IMessageSys = { key: 0, message: '', daley: 3000, color: 'success' }) {
      state.storeSuccess.push(item);
    },
  },
  actions: {
    addSuccess(
      { state, commit },
      { message = 'Успешно сохранено!', daley = 3000, color = 'success', manual = false }: IMessage = <IMessage>{},
    ) {
      const key: number = _genId();
      // color = !color ? 'success' : color;
      commit('addSuccess', <IMessage>{ message, key, color });
      if (manual) return;

      setTimeout(() => {
        let j: number = 0;
        const res = state.storeSuccess.find((item, i) => {
          j = i;
          return item.key == key;
        });
        if (res) commit('removeSuccess', j);
      }, daley);
    },
  },
  getters: {
    storeSuccess: (state): IMessageSys[] => state.storeSuccess,
  },
};
